export const AboutUsBlock = () => import('../../components/storyblok/AboutUsBlock.vue' /* webpackChunkName: "components/about-us-block" */).then(c => wrapFunctional(c.default || c))
export const AboutUsBrandbook = () => import('../../components/storyblok/AboutUsBrandbook.vue' /* webpackChunkName: "components/about-us-brandbook" */).then(c => wrapFunctional(c.default || c))
export const AboutUsNote = () => import('../../components/storyblok/AboutUsNote.vue' /* webpackChunkName: "components/about-us-note" */).then(c => wrapFunctional(c.default || c))
export const AboutUsPhilosophy = () => import('../../components/storyblok/AboutUsPhilosophy.vue' /* webpackChunkName: "components/about-us-philosophy" */).then(c => wrapFunctional(c.default || c))
export const AboutUsRow = () => import('../../components/storyblok/AboutUsRow.vue' /* webpackChunkName: "components/about-us-row" */).then(c => wrapFunctional(c.default || c))
export const AboutUsTwoRowsBlock = () => import('../../components/storyblok/AboutUsTwoRowsBlock.vue' /* webpackChunkName: "components/about-us-two-rows-block" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/storyblok/AppFooter.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const BannerImgLeft = () => import('../../components/storyblok/BannerImgLeft.vue' /* webpackChunkName: "components/banner-img-left" */).then(c => wrapFunctional(c.default || c))
export const BannerImgRight = () => import('../../components/storyblok/BannerImgRight.vue' /* webpackChunkName: "components/banner-img-right" */).then(c => wrapFunctional(c.default || c))
export const BestSellers = () => import('../../components/storyblok/BestSellers.vue' /* webpackChunkName: "components/best-sellers" */).then(c => wrapFunctional(c.default || c))
export const BrandsSlider = () => import('../../components/storyblok/BrandsSlider.vue' /* webpackChunkName: "components/brands-slider" */).then(c => wrapFunctional(c.default || c))
export const ContactUsBlock = () => import('../../components/storyblok/ContactUsBlock.vue' /* webpackChunkName: "components/contact-us-block" */).then(c => wrapFunctional(c.default || c))
export const ContactUsHero = () => import('../../components/storyblok/ContactUsHero.vue' /* webpackChunkName: "components/contact-us-hero" */).then(c => wrapFunctional(c.default || c))
export const ContactUsInfo = () => import('../../components/storyblok/ContactUsInfo.vue' /* webpackChunkName: "components/contact-us-info" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/storyblok/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FooterColumn = () => import('../../components/storyblok/FooterColumn.vue' /* webpackChunkName: "components/footer-column" */).then(c => wrapFunctional(c.default || c))
export const Grid = () => import('../../components/storyblok/Grid.vue' /* webpackChunkName: "components/grid" */).then(c => wrapFunctional(c.default || c))
export const Hero = () => import('../../components/storyblok/Hero.vue' /* webpackChunkName: "components/hero" */).then(c => wrapFunctional(c.default || c))
export const HomeSteps = () => import('../../components/storyblok/HomeSteps.vue' /* webpackChunkName: "components/home-steps" */).then(c => wrapFunctional(c.default || c))
export const InstagramFeed = () => import('../../components/storyblok/InstagramFeed.vue' /* webpackChunkName: "components/instagram-feed" */).then(c => wrapFunctional(c.default || c))
export const LandingPageBlock = () => import('../../components/storyblok/LandingPageBlock.vue' /* webpackChunkName: "components/landing-page-block" */).then(c => wrapFunctional(c.default || c))
export const LatestSlider = () => import('../../components/storyblok/LatestSlider.vue' /* webpackChunkName: "components/latest-slider" */).then(c => wrapFunctional(c.default || c))
export const MainMenuItem = () => import('../../components/storyblok/MainMenuItem.vue' /* webpackChunkName: "components/main-menu-item" */).then(c => wrapFunctional(c.default || c))
export const Page = () => import('../../components/storyblok/Page.vue' /* webpackChunkName: "components/page" */).then(c => wrapFunctional(c.default || c))
export const RegisterComponent = () => import('../../components/storyblok/RegisterComponent.vue' /* webpackChunkName: "components/register-component" */).then(c => wrapFunctional(c.default || c))
export const SimpleImage = () => import('../../components/storyblok/SimpleImage.vue' /* webpackChunkName: "components/simple-image" */).then(c => wrapFunctional(c.default || c))
export const SimpleLink = () => import('../../components/storyblok/SimpleLink.vue' /* webpackChunkName: "components/simple-link" */).then(c => wrapFunctional(c.default || c))
export const Teaser = () => import('../../components/storyblok/Teaser.vue' /* webpackChunkName: "components/teaser" */).then(c => wrapFunctional(c.default || c))
export const TopBarItem = () => import('../../components/storyblok/TopBarItem.vue' /* webpackChunkName: "components/top-bar-item" */).then(c => wrapFunctional(c.default || c))
export const TopBarLink = () => import('../../components/storyblok/TopBarLink.vue' /* webpackChunkName: "components/top-bar-link" */).then(c => wrapFunctional(c.default || c))
export const LatestProductsQuery = () => import('../../components/storyblok/latestProducts/latestProductsQuery.ts' /* webpackChunkName: "components/latest-products-query" */).then(c => wrapFunctional(c.default || c))
export const LatestProductsUseLatestProducts = () => import('../../components/storyblok/latestProducts/useLatestProducts.ts' /* webpackChunkName: "components/latest-products-use-latest-products" */).then(c => wrapFunctional(c.default || c))
export const BestSellersQuery = () => import('../../components/storyblok/bestSellers/bestSellersQuery.ts' /* webpackChunkName: "components/best-sellers-query" */).then(c => wrapFunctional(c.default || c))
export const BestSellersUseBestSellers = () => import('../../components/storyblok/bestSellers/useBestSellers.ts' /* webpackChunkName: "components/best-sellers-use-best-sellers" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
